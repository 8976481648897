<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </template>
    <template #default>
      <span>
        {{ $fanyi("优惠券") }}
      </span>
    </template>
  </headBackBar>
  <div class="coupon">
    <!-- 优惠券顶部分类 -->
    <div class="top-title">
      <div :class="{ active: coupontype == 1 }" @click="Switchingtype(1)">
        {{ $fanyi("未使用") }} ({{ Alllisttotal }})
      </div>
      <div :class="{ active: coupontype == 2 }" @click="Switchingtype(2)">
        {{ $fanyi("已使用") }} ({{ usepagetotal }})
      </div>
      <div :class="{ active: coupontype == 3 }" @click="Switchingtype(3)">
        {{ $fanyi("已过期") }} ({{ expirepagetotal }})
      </div>
    </div>

    <div class="pageBody">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :loading-text="$fanyi('加载中...')"
        :finished-text="$fanyi('')"
        @load="onLoad"
      >
        <!-- 未使用优惠券 -->
        <div class="unused" v-if="coupontype == 1">
          <div class="context" v-for="item in Alllist" :key="item.id">
            <div class="top-text">
              <div class="left-title">
                <!-- 说折扣券也显示代金券的名字 -->
                <h2>
                  {{
                    $fanyi(
                      item.type_name == "折扣券" ? "代金券" : item.type_name
                    )
                  }}
                </h2>
                <p>NO.{{ item.sn }}</p>
              </div>
              <div class="right" v-if="item.type != 3">
                {{
                  item.type_name == "优惠券"
                    ? $fanyi("免手续费")
                    : item.type_name == "代金券"
                    ? item.discounts_amount_jpy
                    : ""
                }}
                <span v-if="item.type_name == '代金券'">{{
                  $fanyi("円")
                }}</span>
              </div>
              <div class="right" v-else>
                {{
                  item.discounts_amount_jpy && item.discounts_amount_jpy != "0"
                    ? item.discounts_amount_jpy
                    : Math.round(100 - item.discounts_rate) + "%"
                }}
                <!-- {{ Math.round(100 - item.discounts_rate) }}% -->
                <span>{{
                  item.discounts_amount_jpy && item.discounts_amount_jpy != "0"
                    ? "円"
                    : $fanyi("减免")
                }}</span>
              </div>
            </div>
            <!-- 使用限制 -->
            <div class="serviceConditions" v-if="item.type == 3">
              <div class="conditionsItem">
                <span>{{ $fanyi("使用条件") }}：</span>
                <span>{{ item.logistics_Name || $fanyi("所有物流") }}；</span>
              </div>
              <div class="conditionsItem">
                <span>{{ $fanyi("计费重量") }}：</span>
                <span>{{ item.min_weight }}KG；</span>
              </div>
              <div class="conditionsItem">
                <span>{{ $fanyi("金额") }}：</span>
                <span>{{ item.min_consumption_jpy }}円；</span>
              </div>
            </div>
            <hr />
            <!-- 有效期 -->
            <div class="botton-text">
              <p>
                {{ $fanyi("有效期") }}：
                <span>
                  {{ dayjs(item.effective_date).format("YYYY.MM.DD") }} —
                  {{ dayjs(item.expiry_date).format("YYYY.MM.DD") }}</span
                >
              </p>
              <p>
                {{
                  item.type_name == "优惠券"
                    ? "注文書"
                    : item.type_name == "代金券"
                    ? "配送依頼書"
                    : item.type_name == "折扣券"
                    ? "配送依頼書"
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- 已使用优惠券 -->
        <div class="Alreadyinuse" v-if="coupontype == 2">
          <div class="main" v-for="item in usepagelist" :key="item.id">
            <div class="context">
              <div class="top-text">
                <div class="left-title">
                  <h2>
                    {{
                      $fanyi(
                        item.type_name == "折扣券" ? "代金券" : item.type_name
                      )
                    }}
                  </h2>
                  <p>NO.{{ item.sn }}</p>
                </div>
                <div class="right" v-if="item.type != 3">
                  {{
                    item.type_name == "优惠券"
                      ? $fanyi("免手续费")
                      : item.type_name == "代金券"
                      ? item.discounts_amount_jpy
                      : ""
                  }}
                  <span v-if="item.type_name == '代金券'">{{
                    $fanyi("円")
                  }}</span>
                </div>
                <div class="right" v-else>
                  {{
                    item.discounts_amount_jpy &&
                    item.discounts_amount_jpy != "0"
                      ? item.discounts_amount_jpy
                      : Math.round(100 - item.discounts_rate) + "%"
                  }}
                  <!-- {{ Math.round(100 - item.discounts_rate) }}% -->
                  <span>{{
                    item.discounts_amount_jpy &&
                    item.discounts_amount_jpy != "0"
                      ? "円"
                      : $fanyi("减免")
                  }}</span>
                </div>
              </div>
              <!-- 使用限制 -->
              <div class="serviceConditions" v-if="item.type == 3">
                <div class="conditionsItem">
                  <span>{{ $fanyi("使用条件") }}：</span>
                  <span>{{ item.logistics_Name || $fanyi("所有物流") }}；</span>
                </div>
                <div class="conditionsItem">
                  <span>{{ $fanyi("计费重量") }}：</span>
                  <span>{{ item.min_weight }}KG；</span>
                </div>
                <div class="conditionsItem">
                  <span>{{ $fanyi("金额") }}：</span>
                  <span>{{ item.min_consumption_jpy }}円；</span>
                </div>
              </div>
              <hr />
              <!-- 有效期 -->
              <div class="botton-text">
                <p>
                  {{ $fanyi("有效期") }}：
                  <span>
                    {{ dayjs(item.effective_date).format("YYYY.MM.DD") }} —
                    {{ dayjs(item.expiry_date).format("YYYY.MM.DD") }}</span
                  >
                </p>
                <p>
                  {{
                    item.use_order_sn
                      ? $fanyi("注文書")
                      : item.use_porder_sn
                      ? $fanyi("配送依頼書")
                      : ""
                  }}
                </p>
              </div>
              <img src="../../assets/coupon/yishiyongyouhuiquan.png" alt="" />
            </div>
            <!-- 使用时间 -->
            <div class="usertime">
              <div class="time">
                {{ $fanyi("使用时间") }}：<span>{{ item.use_datetime }}</span>
              </div>
              <span class="order"
                >{{ $fanyi("订单号") }} :{{
                  item.use_order_sn
                    ? item.use_order_sn
                    : item.use_porder_sn
                    ? item.use_porder_sn
                    : ""
                }}</span
              >
            </div>
          </div>
        </div>

        <!-- 已过期优惠券 -->
        <div class="expired" v-if="coupontype == 3">
          <div class="context" v-for="item in expirelist" :key="item.id">
            <div class="top-text">
              <div class="left-title">
                <h2>
                  {{
                    $fanyi(
                      item.type_name == "折扣券" ? "代金券" : item.type_name
                    )
                  }}
                </h2>
                <p>NO.{{ item.sn }}</p>
              </div>
              <div class="right" v-if="item.type != 3">
                {{
                  item.type_name == "优惠券"
                    ? $fanyi("免手续费")
                    : item.type_name == "代金券"
                    ? item.discounts_amount_jpy
                    : ""
                }}<span v-if="item.type_name == '代金券'">{{
                  $fanyi("円")
                }}</span>
              </div>
              <div class="right" v-else>
                {{
                  item.discounts_amount_jpy && item.discounts_amount_jpy != "0"
                    ? item.discounts_amount_jpy
                    : Math.round(100 - item.discounts_rate) + "%"
                }}
                <!-- {{ Math.round(100 - item.discounts_rate) }}% -->
                <span>{{
                  item.discounts_amount_jpy && item.discounts_amount_jpy != "0"
                    ? "円"
                    : $fanyi("减免")
                }}</span>
              </div>
            </div>
            <!-- 使用限制 -->
            <div class="serviceConditions" v-if="item.type == 3">
              <div class="conditionsItem">
                <span>{{ $fanyi("使用条件") }}：</span>
                <span>{{ item.logistics_Name || $fanyi("所有物流") }}；</span>
              </div>
              <div class="conditionsItem">
                <span>{{ $fanyi("计费重量") }}：</span>
                <span>{{ item.min_weight }}KG；</span>
              </div>
              <div class="conditionsItem">
                <span>{{ $fanyi("金额") }}：</span>
                <span>{{ item.min_consumption_jpy }}円；</span>
              </div>
            </div>
            <hr />
            <!-- 有效期 -->
            <div class="botton-text">
              <p>
                {{ $fanyi("有效期") }}：
                <span>
                  {{ dayjs(item.effective_date).format("YYYY.MM.DD") }} —
                  {{ dayjs(item.expiry_date).format("YYYY.MM.DD") }}</span
                >
              </p>
            </div>
            <!-- 使用时间有效期 -->
            <img src="../../assets/coupon/yiguoqi.png" alt="" />
          </div>
        </div>
        <noData
          v-if="
            !loading &&
            ((!Alllist.length && coupontype == 1) ||
              (!usepagelist.length && coupontype == 2) ||
              (!expirelist.length && coupontype == 3))
          "
        />
      </van-list>
    </div>
  </div>
</template>
<script setup>
import noData from "./components/nodata.vue";
import dayjs from "dayjs";
import headBackBar from "../../components/headGoBack";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();

// 优惠券类型 1 是未使用  2 已使用，3已过期
const coupontype = ref(1);
// 未使用
const page = ref(0);
const Alllist = ref([]);
const Alllisttotal = ref(0);
// 已使用
const usepage = ref(0);
const usepagelist = ref([]);
const usepagetotal = ref(0);
// 3.已过期
const expire = ref(0);
const expirelist = ref([]);
const expirepagetotal = ref(0);
const loading = ref(false);
const finished = ref(false);
Toast.loading({
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
const created = async () => {
  // 已使用的
  const res = await proxy.$api.couponRecord({
    discount_status: 2,
    page: 1,
    pageSize: 20,
  });
  usepagetotal.value = res.data.total;
  // 已过期的
  const data = await proxy.$api.couponRecord({
    discount_status: -1,
    page: 1,
    pageSize: 20,
  });
  expirepagetotal.value = data.data.total;
};
created();
// 优惠券状态切换
const Switchingtype = (val) => {
  finished.value = false;
  coupontype.value = val;
  if (val == 2 && usepagelist.value.length == 0) {
    loading.value = true;
    onLoad();
  }
  if (val == 3 && expirelist.value.length == 0) {
    loading.value = true;
    onLoad();
  }
};
// 处理数据
const dataCC = (v) => {
  // console.log(v);
  v.forEach((vItem) => {
    if (
      vItem.type == 3 &&
      vItem.logistics_group &&
      vItem.logistics_group.length
    ) {
      let strArr = [];
      vItem.logistics_group.forEach((groupJJ) => {
        strArr.push(groupJJ.logistics_name);
      });
      vItem.logistics_Name = strArr.join(",");
    }
  });
};
const onLoad = async () => {
  // 如果是未使用的
  if (coupontype.value == 1) {
    finished.value = false;
    page.value += 1;
    const res = await proxy.$api.couponGet({
      page: page.value,
      pageSize: 20,
    });
    if (res.code !== 0) {
      loading.value = false;

      finished.value = true;

      proxy.$fanyi(res.msg);
      Toast.clear();
      return;
    }
    if (res.data.data.length < 6 || res.data.total < 6) {
      finished.value = true;
    }
    dataCC(res.data.data);
    Alllist.value = [...Alllist.value, ...res.data.data];
    Alllisttotal.value = res.data.total;

    loading.value = false;
    if (Alllist.value.length == res.data.total) {
      finished.value = true;
    }
  }
  // 如果是已使用的
  if (coupontype.value == 2) {
    finished.value = false;
    usepage.value += 1;
    const res = await proxy.$api.couponRecord({
      discount_status: 2,
      page: usepage.value,
      pageSize: 20,
    });
    if (res.data.data.length < 6 || res.data.total < 6) {
      finished.value = true;
    }
    dataCC(res.data.data);
    usepagelist.value = [...usepagelist.value, ...res.data.data];
    loading.value = false;
    if (usepagelist.value.length == res.data.total) {
      finished.value = true;
    }
  }
  // 如果是已过期的
  if (coupontype.value == 3) {
    finished.value = false;
    expire.value += 1;
    const res = await proxy.$api.couponRecord({
      discount_status: -1,
      page: expire.value,
      pageSize: 20,
    });
    if (res.data.data.length < 6 || res.data.total < 6) {
      finished.value = true;
    }
    dataCC(res.data.data);
    Toast.clear();
    expirelist.value = [...expirelist.value, ...res.data.data];
    loading.value = false;
    if (expirelist.value.length == res.data.total) {
      finished.value = true;
    }
  }
  Toast.clear();
};
//------------------------ data -------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.pageBody {
  padding-top: 20px;
}
.coupon {
  padding: 0 30px;
  width: 750px;
  min-height: 1400px;

  background: #f6f6f6;

  // 未使用优惠卷
  .top-title {
    width: 100%;
    display: flex;
    align-items: center;
    height: 86px;
    font-size: 28px;
    position: sticky;
    top: 100px;
    z-index: 2;
    background-color: #f6f6f6;
    font-weight: 400;
    color: #010101;
    div {
      margin-right: 44px;
    }

    .active {
      font-size: 28px;

      font-weight: 600;
      color: #b4272b;
    }
  }

  .unused {
    .context {
      margin-bottom: 15px;
      padding: 36px 36px 24px 36px;
      width: 690px;
      min-height: 200px;
      background: #ffffff;
      border: 1px solid #b4272d;
      border-radius: 20px;

      .top-text {
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        .left-title {
          min-width: 325px;

          h2 {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #010101;
            margin-bottom: 15px;
          }

          p {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        .right {
          min-width: 210px;
          text-align: right;
          font-size: 48px;
          line-height: 48px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #b4272d;
          height: 63px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          // overflow: hidden;
        }
      }
      hr {
        margin-top: 25px;
        border: none;
        border-bottom: 3px dashed #dfdfdf;
      }
      .botton-text {
        display: flex;
        padding-top: 20px;

        p {
          &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;

            span {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }

          &:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 24px;
            padding: 0 10px;
            min-width: 80px;
            height: 32px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b4272d;
            background: #ffe9e9;
            border-radius: 6px;
          }
        }
      }
    }
  }
  //已使用优惠券
  .Alreadyinuse {
    margin-bottom: 15px;
    .main {
      margin-bottom: 15px;
    }
    .context {
      padding: 36px 36px 24px 36px;
      width: 690px;
      min-height: 200px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 20px;
      position: relative;

      img {
        position: absolute;
        right: 15px;
        width: 106px;
        height: 88px;
        top: 87px;
      }

      .top-text {
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        .left-title {
          min-width: 325px;

          h2 {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #010101;
            margin-bottom: 15px;
          }

          p {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        .right {
          min-width: 210px;
          text-align: right;
          font-size: 48px;
          line-height: 48px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #b4272d;
          height: 63px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          // overflow: hidden;
        }
      }
      hr {
        margin-top: 25px;
        border: none;
        border-bottom: 3px dashed #dfdfdf;
      }

      .botton-text {
        display: flex;
        padding-top: 20px;

        p {
          &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;

            span {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }

          &:last-child {
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 24px;
            min-width: 80px;
            height: 32px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b4272d;
            background: #ffe9e9;
            border-radius: 6px;
          }
        }
      }
    }

    .usertime {
      width: 690px;
      height: 115px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 20px;
      padding-top: 25px;
      padding-left: 35px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      .time {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-bottom: 18px;

        span {
          font-size: 24px;
          line-height: 24px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 18px;
        }
      }

      .order {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        height: 26px;

        text-decoration: underline;
        color: #b4272d;
      }
    }
  }

  .expired {
    .context {
      position: relative;
      padding: 36px 36px 24px 36px;
      width: 690px;
      min-height: 200px;
      margin-bottom: 15px;
      border-radius: 20px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      img {
        position: absolute;
        right: 15px;
        width: 106px;
        height: 88px;
        top: 87px;
      }

      .top-text {
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        .left-title {
          min-width: 325px;

          h2 {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #999999;
            margin-bottom: 15px;
          }

          p {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        .right {
          font-size: 60px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #999999;
          height: 63px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          span {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .serviceConditions {
        span {
          color: #666;
        }
      }
      hr {
        margin-top: 25px;
        border: none;
        border-bottom: 3px dashed #dfdfdf;
      }
      .botton-text {
        display: flex;
        padding-top: 20px;

        p {
          &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;

            span {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
    }

    // 已过期优惠券
  }
}
.serviceConditions {
  display: flex;
  flex-wrap: wrap;
  .conditionsItem {
    span {
      font-size: 23px;
      line-height: 28px;
    }
  }
}
</style>
